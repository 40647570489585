import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import CardStats from 'components/Cards/CardStats'
import ResultFilterAction from './../../actions/resultsFilter'
import host from 'config/host';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

export const JobResultsHeader = (props) => {
    const [joptions,setjoptions] = useState(['All Jobs'])
    const joboptions = joptions
    const levelOptions = ['All Levels', 'Level 1', 'Level 2', 'Level 3','Level 4', 'Level 5']
    const [jobs, setJobs] = useState(joboptions[0]);
    const [levels, setLevels] = useState(joptions[0]);
    const [level5,setLevel5] = useState(true)
    const [level4,setLevel4] = useState(true)
    const [level3,setLevel3] = useState(true)
    const [level2,setLevel2] = useState(true)
    const [level1,setLevel1] = useState(true)
    const [isLoading,setIsLoading] = useState(false);
    const [data,setdata] = useState(null);
    const [totalpageno,setTotalpageno] = useState(1);

    const filter = useSelector(state => state.ResultsFilterReducer)
    filter.then(value=>{
      console.log(value.jobs)
      setLevels(value.jobs)
      setJobs(value.jobs)
    })

    const DownloadFile = async()=>{
      setIsLoading(true);
      try{
      await fetch(host.nameserver+'/file/results',{
        method:'POST',
        headers: {
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        'Content-type': 'application/json; charset=UTF-8',
      },
      body:JSON.stringify({
        email:localStorage.getItem('email'),
        offset:25,
        pageno:1,
        levels:JSON.stringify({level1,level2,level3,level4,level5}),
        jobs:jobs
      })
      }).then((res)=>{
        return res.json();
      }).then((data)=>{
        console.log(data);
        setdata(data.results);
        setTotalpageno(Math.ceil(data.count/25))
        setIsLoading(false);
      })
    }catch(err){
      console.log(err)
      setIsLoading(false);
    }
    }

  



    const dispatch = useDispatch();
    useEffect(()=>{
      console.log(data)
      console.log(level1,level2,level3,level4,level5)
      if(data!=null){
        dispatch(ResultFilterAction({
            jobs:jobs,
            levels:{level1,level2,level3,level4,level5},
            data:data,
            loading:isLoading,
            totalpageno:totalpageno
        }))
      }
    },[data,isLoading,level1,level2,level3,level4,level5])

    useEffect(()=>{
        fetch(host.nameserver + '/file/getjobs', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('token'),
              'Content-type': 'application/json; charset=UTF-8',
            },
          }).then((res)=>{
              return res.json()
          }).then((data)=>{
              if( data.jobs && data.jobs.length>0){
                  let jobsarray=[]
                  jobsarray.push('All Jobs')
                data.jobs.map((job)=>{
                    jobsarray.push(job.jobid+" "+new Date(job.createdAt).toLocaleDateString('en-us'))
                })
                setjoptions(jobsarray)
              }
          }).catch((err)=>{
              console.log(err);
          })
    },[])
    return (
        <>
         <div data-tut="pheader" className="relative bg-white md:pt-32 pb-12 pt-12">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-4/12 px-12 py-4" >
              <Dropdown options={joboptions} value={levels}   onChange={(e)=>{dispatch(ResultFilterAction({
            jobs:e.value,
            levels:{level1,level2,level3,level4,level5},
            data:data,
            loading:isLoading,
            totalpageno:totalpageno
        })) }}  placeholder="Select an option" />
              </div>
              <div className="w-full lg:w-6/12 xl:w-8/12 px-4 py-4" >
              <FormGroup style={{flexDirection:'row'}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={level5}
              onChange={(e)=>{setLevel5(e.target.checked)}}
            />
          }
          label="Level 5"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={level4}
              onChange={(e)=>{setLevel4(e.target.checked) }}
            />
          }
          label="Level 4"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={level3}
              onChange={(e)=>{if(e.target.checked) setLevel3(true); else setLevel3(false) }}
            />
          }
          label="Level 3"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={level2}
              onChange={(e)=>{setLevel2(e.target.checked)}}
            />
          }
          label="Level 2"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={level1}
              onChange={(e)=>{setLevel1(e.target.checked)}}
            />
          }
          label="Level 1"
        />
        </FormGroup>
        </div>
              <div className="w-full lg:w-12/12 xl:w-12/12 px-12 py-1" style={{alignItems:"flex-end",justifyContent:"flex-end",display:"flex"}} >
              <button
                      className="bg-blueGray-800 capitalise text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      style={{backgroundColor:'#285A84', fontWeight:'bold',fontFamily:"Tenor Sans",fontSize:20, width:'100%' }}
                      onClick={()=>{DownloadFile()}}
                    >
                      Filter
                    </button>
              </div>
            </div>
          </div>
          </div>
          {/* <h1 style={{color:"white",textAlign:"center",fontWeight:"bolder",marginBottom:10,marginTop:20}}>{currentLevel} PREDICTIONS DISTRIBUTION</h1> */}
        </>
    )
}

const mapStateToProps = (state) => ({
  filter: state.ResultsFilterReducer
})


export default connect(mapStateToProps)(JobResultsHeader)
