import React, { useEffect, useState } from "react";
import {connect, useDispatch, useSelector} from 'react-redux'
import Tour from "reactour";
import "./styles.css";
import { trueGray } from "tailwindcss/colors";
import host from "config/host";
import ResultFilterAction from "actions/resultsFilter";
import LoadingOverlay from "react-loading-overlay";
// components

function CardJobDetails(props) {
const [jobdata, setjobdata] = useState(null);
props.jobdata.then(data=>{
  if(data!=null){
    setjobdata(data.jobdata)
  }
})
const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })

  const [level5,setLevel5] = useState(true)
    const [level4,setLevel4] = useState(true)
    const [level3,setLevel3] = useState(true)
    const [level2,setLevel2] = useState(true)
    const [level1,setLevel1] = useState(true)
    const [isLoading,setIsLoading] = useState(false);
    const [data,setdata] = useState(null);
    const [totalpageno,setTotalpageno] = useState(1);

    const GetJobResults = async()=>{
      setIsLoading(true);
      try{
      await fetch(host.nameserver+'/file/results',{
        method:'POST',
        headers: {
          'Authorization': 'Bearer '+localStorage.getItem('token'),
        'Content-type': 'application/json; charset=UTF-8',
      },
      body:JSON.stringify({
        email:localStorage.getItem('email'),
        offset:25,
        pageno:1,
        levels:JSON.stringify({level1,level2,level3,level4,level5}),
        jobs:jobdata.Job.jobid
      })
      }).then((res)=>{
        return res.json();
      }).then((data)=>{
        console.log(data);
        setdata(data.results);
        setTotalpageno(Math.ceil(data.count/25))
        setIsLoading(false);
        checkResults()
      })
    }catch(err){
      console.log(err)
      setIsLoading(false);
    }
    }

const dispatch = useDispatch()

const checkResults = async()=>{
  
  await dispatch(ResultFilterAction({
    jobs:jobdata.Job.jobid+" "+new Date(jobdata.Job.createdAt).toLocaleDateString('en-us'),
    levels:{level1,level2,level3,level4,level5},
    data:data,
    loading:false,
    totalpageno:totalpageno
}))
props.props.history.push('/results/view')

}


const [tourOpen,setTourOpen]=useState(true);
const [tour,setTour] = useState(false);
const tourConfig = [
  {
    selector: '[data-tut="jobdata"]',
    content: `You can check the details of the batch of records`
  },
  {
    selector: '[data-tut="checkresult"]',
    content: `To get the predictions on the uploaded applicants, click here.`
  },
  
]

  return (
    <>
    <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />
         <LoadingOverlay
      active={isLoading}
  spinner
  text='loading please wait...'
  >
      {jobdata ? <div data-tut="jobdata" className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 mt-32 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <h1 style={{textAlign:'center',fontWeight:'bold',fontSize:25}}>Job summary</h1>
          <div style={{width:100+"%",flexDirection:'row',borderStyle:'solid',borderWidth:1,borderColor:'#eeeeee'}}></div>
        <div style={{display:"flex",flexDirection:'column',justifyContent:'center',alignItems:'center',padding:10}}>
          <div style={{fontSize:20,padding:10}}>Job ID: {jobdata.Job.jobid}</div>
          <div style={{fontSize:20,padding:10}}>Job submitted time: {new Date(jobdata.Job.createdAt).toLocaleString('en-us')}</div>
          <div style={{fontSize:20,padding:10}}>Job submitted source: {jobdata.Job.source}</div>
          <div style={{fontSize:20,padding:10}}> Submitted by: {jobdata.useruploaded.email}</div>
          <div style={{fontSize:20,padding:10}}> Total number of records: {jobdata.Job.records?jobdata.Job.records:0}</div>
          <div style={{fontSize:20,padding:10}}> Error: {jobdata.Job.error ? isNaN(jobdata.Job.error) ? jobdata.Job.error : jobdata.Job.error +" records": 'No Errors' }</div>
        </div>
        <button
                      className="bg-blueGray-800 capitalise text-white active:bg-blueGray-600 text-sm font-bold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      style={{backgroundColor:"#285A84",fontSize:20}}
                      data-tut="checkresult"
                    onClick={()=>{
                     if(jobdata.Job.state=="processed"){
                       GetJobResults();
                     }
                    }}
                    >
                      Check results of this job
                      </button>
        </div>
      </div>:<h1 style={{display:"flex",height:80+'vh', justifyContent:'center',alignItems:'center'}}>Loading please wait....</h1>}
      </LoadingOverlay>
    </>
  );
}
const mapStateToProps = state => ({
  loginstate: state.LoginReducer,
  jobdata:state.JobDataReducer
});

export default connect(mapStateToProps)(CardJobDetails)

