import React, { useState,useEffect } from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { useSelector,useDispatch,connect } from 'react-redux';

function HeaderStats(props) {
  const[data,setData]=useState(null)
  const [role,setRole] = useState(null);
  useEffect(() => {
  setRole(localStorage.getItem('role'))
  }, [])
props.data.then(value=>{
  if(value.data!=null && value.data.count>0)
  setData(value.data.vals);
})
const [currentLevel,setCurrentLevel]=useState("LEVEL 5")
  return (
    <>
      {/* Header */}
      <div className="relative bg-white md:pt-32 pb-4 pt-12">
       {data!=null ? props.location=="/admin/dashboard" || props.location=="/Admin/Dashboard" ? <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-4/12 px-1 pb-6 " onClick={()=>{props.history.push('/results/view')}}>
                <CardStats
                  statSubtitle="TOTAL APPLICATION PREDICTIONS"
                  statTitle="10000"
                  
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 py-4" onClick={()=>{setCurrentLevel("LEVEL 5")}}>
                <CardStats
                  statSubtitle="Level 5"
                  statTitle="4000"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 py-4" onClick={()=>{setCurrentLevel("LEVEL 4")}}>
                <CardStats
                  statSubtitle="Level 4"
                  statTitle="1400"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 py-4" onClick={()=>{setCurrentLevel("LEVEL 3")}}>
                <CardStats
                  statSubtitle="Level 3"
                  statTitle="1600"
                  statIconColor="bg-emerald-500"
                />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 py-4" onClick={()=>{setCurrentLevel("LEVEL 2")}}>
                <CardStats
                  statSubtitle="Level 2"
                  statTitle="2000"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 px-4 py-4" onClick={()=>{setCurrentLevel("LEVEL 1")}}>
                <CardStats
                  statSubtitle="Level 1"
                  statTitle="1000"
                  statIconColor="bg-orange-500"
                />
              </div>
            </div>
          </div>
          {/* <h1 style={{color:"white",textAlign:"center",fontWeight:"bolder",marginBottom:10,marginTop:20}}>{currentLevel} PREDICTIONS DISTRIBUTION</h1> */}
        </div>:
        <div className="px-4 md:px-10 mx-auto w-full">
        <div>
          {/* Card stats */}
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 xl:w-4/12 px-2">
              <CardStats
                statSubtitle="TOTAL APPLICATIONS"
                statTitle={data.total}
              />
            </div> 
            <div className="w-full lg:w-12/12 xl:w-4/12 px-2">
              <CardStats
                statSubtitle="APPLICATIONS PROCESSING"
                statTitle={data.waiting + data.processing}
                statIconColor="bg-orange-500"
              />
            </div> 
            <div className="w-full lg:w-12/12 xl:w-4/12 px-2">
              <CardStats
                statSubtitle="APPLICATIONS PROCESSED"
                statTitle={data.processed}
                statIconColor="bg-emerald-500"
              />
            </div> 
            </div>
            </div>
            </div>:
        <h3 style={{zIndex:100,width:100+"%",justifyContent:"center",alignItems:"center",textAlign:"center",color:"white"}}>Please upload the data to view the analytics</h3>}
       {props.location.includes("/admin/tables") && <div  className="flex flex-wrap mt-4 z-50 px-12">
     <div  data-tut="modomagic" style={{ width:100+"%",justifyContent:"center",alignItems:"center",textAlign:"center",cursor:"pointer",color:"#285A84",backgroundColor:"#285A84"}} onClick={()=>{ if(props.history.location.pathname=="/admin/tables/fetchdata") props.history.push("/admin/tables"); else props.history.push("/admin/tables/fetchdata")}}>
  {role=="admin" && <p style={{padding:20,borderStyle:"solid",borderWidth:1,borderColor:"white",color:"white",fontWeight:"bolder",borderRadius:5,fontFamily:"Montserrat SemiBold",fontSize:22}}>{props.history.location.pathname=="/admin/tables/fetchdata"?'Show details':'Upload applications'}</p>}
    </div>
    </div>}
      </div>
    </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
});

export default connect(mapStateToProps)(HeaderStats)