import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import logo from './../assets/img/modo.png'
// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import PasswordReset from "views/auth/passwordReset";

export default function Auth() {
  
  const [bigScreen, setBigScreen] = useState(window.matchMedia("(min-width: 768px)").matches)
  useEffect(() => {
    const handler = e => setBigScreen(e.matches)
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }, [])
  console.log(bigScreen);
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-600 bg-no-repeat bg-full"
            style={{
                backgroundColor:'white',
                display:'flex',
                width:100+"%"
            }}
          >
            <img src={logo} style={{display:"flex",height:100,width:!bigScreen?90+"%":25+"%",justifyContent:'center',textAlign:'center',alignItems:'center',paddingLeft:20,zIndex:1000}}></img>
          </div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/passwordreset" exact component={PasswordReset} />
            <Route path="/" exact component={Login} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
