import React from "react";
import Chart from "chart.js";

export default function Race() {
  React.useEffect(() => {
    let config = {
      type: "bar",
      data: {
        labels: [
          "A",
          "A;I",
          "A;O",
          "A;P",
          "A;P;W",
          "A;W",
          "I;W",
          "O",
          "O;W",
          "P",
          "P;W",
          "W",
          "A",
          "A;I",
          "A;O",
          "A;P",
          "A;P;W",
          "A;W",
          "I;W",
          "O",
          "O;W",
          "P",
          "P;W",
          "W",

        ],
        datasets: [
          {
            label: "Level 1",
            backgroundColor: "#285A84",
            borderColor: "#ed64a6",
            data: [3.8,7.1,0.1,0.1,0.1,3.8,0.1,2.6,2.6,4.1,0.1,5.6,3.8,7.1,0.1,0.1,0.1,3.8,0.1,2.6,2.6,4.1,0.1,5.6],
            fill: false,
            barThickness: 15,
          },
          {
            label: "Level 2",
            fill: false,
            backgroundColor: "#6d4c41",
            borderColor: "#4c51bf",
            data: [3.1,0.2,0.2,1.0,0.0,4.4,4.0,1.9,1.5,7.0,0.2,5.5,3.8,7.1,0.1,0.1,0.1,3.8,0.1,2.6,2.6,4.1,0.1,5.6],
            barThickness: 15,
          },
          {
            label: "Level 3",
            fill: false,
            backgroundColor: "#004d4",
            borderColor: "#4c51bf",
            data: [3.1,2.2,0.2,0.0,2.0,4.4,0.0,1.9,1.5,0.0,0.2,5.5,3.8,7.1,0.1,0.1,0.1,3.8,0.1,2.6,2.6,4.1,0.1,5.6],
            barThickness: 15,
          },
          {
            label: "Level 4",
            fill: false,
            backgroundColor: "#c62828",
            borderColor: "#4c51bf",
            data: [3.1,4.2,3.2,4,0.0,4.4,0.0,1.9,1.5,0.0,0.2,5.5,3.8,7.1,0.1,0.1,0.1,3.8,0.1,2.6,2.6,4.1,0.1,5.6],
            barThickness: 15,
          },
          {
            label: "Level 5",
            fill: false,
            backgroundColor: "#263238",
            borderColor: "#4c51bf",
            data: [3.1,1.2,5.2,0.0,0.0,4.4,0.0,1.9,1.5,0.0,0.2,5.5,3.8,7.1,0.1,0.1,0.1,3.8,0.1,2.6,2.6,4.1,0.1,5.6],
            barThickness: 15,
          },
        ],
      },
      options: {
        responsive: false,
        legend: {
           position: 'right' // place legend on the right side of chart
        },
        scales: {
           xAxes: [{
              stacked: true // this should be set to make the bars stacked
           }],
           yAxes: [{
              stacked: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              } // this also..
           }]
        }
     }
    };
    let ctx = document.getElementById("bar-chart4").getContext("2d");
    window.myBar = new Chart(ctx, config);
  }, []);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded" style={{display:"flex",flex:1,width:100+"%"}}>
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent" style={{display:"flex",flex:1,width:100+"%"}}>
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Distribution
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
              Race
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="bar-chart4" style={{width:100+"%",height:100+"%"}}></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
