import JobErrorsTable from 'components/Cards/JobErrorsTable'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Sidebar from 'components/Sidebar/Sidebar'
import host from 'config/host'
import React, { useEffect, useState } from 'react'
import {connect, useDispatch} from 'react-redux'
import Pagination from 'react-responsive-pagination';
import './../admin/bootstapmin.css'

function JobErrors(props) {
    const [jobdata, setJobData] = useState(null)
    props.jobdata.then(data=>{
        if( data.jobdata!=null)
           { 
               setJobData(data.jobdata);
               if(!isNaN( data.jobdata.Job.error)){
               setTotalPageno(Math.ceil(parseInt(data.jobdata.Job.error)/20))
            console.log(totalpageno)
        }
            }

    })
    const [data, setdata] = useState(null)
    const [pageno,setpageno] = useState(1);
    const [totalpageno,setTotalPageno]=useState(0)
    const [offset,setOffset] = useState(20);
    useEffect(() => {
      console.log(jobdata)
       if(jobdata!=null){
         fetch(host.nameserver + '/file/joberrors', {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization':'Bearer '+localStorage.getItem('token')
              },
            method: 'POST',
            body: JSON.stringify({
                email:localStorage.getItem('email'),
                pageno:pageno,
                offset:offset,
                jobid:jobdata.Job.jobid  
            }),
          })
            .then((res) => {
              if (res.status === 200) return res.json();
            })
            .then(async(data) => {
              setdata(data.errors)
              console.log(data)
            })
            .catch((err) => {
              console.log(err);
            });
       }
    }, [jobdata,pageno])
    return (
        <>
         {data==null ? <div style={{height:60+"vh",textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center",marginTop:20}}>Loading</div>:null}
     {data!=null && data.length>0 ? <div className="flex flex-wrap mt-16">
        <div className="w-full mb-12 px-4 mt-2">
          <JobErrorsTable color="light" data={data} history={props.history} />
        </div>
        <Pagination
     current={pageno}
     total={totalpageno}
     onPageChange={(num)=>{setpageno(num)}}
    //   onPageChange={(num)=>{setCurrentPage(num); props.history.push('#')}}
    />
  </div>:<div style={{height:60+'vh',display:'flex',justifyContent:'center',alignItems:'center',marginTop:20}}>No Errors found</div>} 
        </>
    )
}

const mapStateToProps = state => ({
    loginstate: state.LoginReducer,
    jobdata:state.JobDataReducer
  });
  
  export default connect(mapStateToProps)(JobErrors)

