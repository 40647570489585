import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import LoadingOverlay from 'react-loading-overlay';
import { Snackbar } from "material-ui";
import Tour from "reactour";
import Alert from 'react-bootstrap/Alert'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { useSelector,useDispatch } from 'react-redux';
import LoginAction from "actions/login";
import host from "config/host";
import logo from './../../assets/img/modo.png'
import GuideAction from "actions/guide";
import { FaEye } from "react-icons/fa";
export default function Login(props) {
  const state = useSelector(state => state.LoginReducer)
  state.then(value=>{
    if(value.Loggedin==true){
    if(value.role==="owner"){
      props.history.push("/owner/register")
    }
    else {
      props.history.push("/admin/dashboard")
    }
  }
  })
const dispatch = useDispatch();
const firebaseConfig =  {
  apiKey: "AIzaSyAS5G3IJekPHHl896XwFq5wkVZzi2MV6iM",
  authDomain: "rush-sales.firebaseapp.com",
  projectId: "rush-sales",
  storageBucket: "rush-sales.appspot.com",
  messagingSenderId: "47232927291",
  appId: "1:47232927291:web:1046e45fd0e2a464ce3e1e",
  measurementId: "G-BVXKK62FD4"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}

const LoginWithToken=async(token)=>{
await fetch(host.nameserver + '/user/auth/signin', {
  method: 'POST',
  body: JSON.stringify({firebasetoken:token}),
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
})
  .then((res) => {
    setIsLoading(true);
    if (res.status === 200) return res.json();
  })
  .then(async(data) => {
  if(data.user.guidetour==undefined)
  await dispatch(GuideAction(true));
  else
  await dispatch(GuideAction(data.user.guidetour));
  console.log("Guide Tour:",data.user.guidetour)
await localStorage.setItem('loggedin',true);
await localStorage.setItem('token',data.token);
await localStorage.setItem('role',data.user.role);
await localStorage.setItem('email',data.user.email);
await localStorage.setItem('tour',false);
await dispatch(LoginAction(data.user.email,data.user.role,false));
  setSnackBarMessage("Sign in success")
showSnackbar(true);
setTimeout(async()=>{
  showSnackbar(false);
},1000)
  })
  .catch((err) => {
    setIsLoading(false);
setSnackBarMessage("Sign in failed")
localStorage.clear(); 
sessionStorage.clear();  
auth.signOut(); 
dispatch(LoginAction("","",true));
showSnackbar(true);
setTimeout(async()=>{
  showSnackbar(false);
 
},1000)
  });

}

const auth = firebase.auth();
useEffect(() => { 

  auth.onAuthStateChanged(async userAuth => {
    console.log(userAuth)
   if(userAuth!= null && userAuth!== undefined ){
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
      // Send token to your backend via HTTPS
      // ...
      setIsLoading(true);
      LoginWithToken(idToken);
    }).catch(function(error) {
      // Handle error
      setIsLoading(false);
      setSnackBarMessage('Couldnot create an account');
      showSnackbar(true);
      setTimeout(()=>{
        showSnackbar(false);
      },1000)
    });
   }
  });
}, [])


const [passwordshow,setpasswordshow] = useState(false);
const [email, setEmail] = useState("");
const [password, setPassword] = useState("")
const [snackbar,showSnackbar] = useState(false)
const [message,setSnackBarMessage]=useState("")
const [isLoading,setIsLoading] = useState(false);
const signin = async()=>{
 setIsLoading(true);
await auth.signInWithEmailAndPassword(email, password).catch(error => {
  console.error("Error signing in with password and email", error);
  setIsLoading(false);
  setSnackBarMessage("Username/Password wrong")
  showSnackbar(true);
  setTimeout(()=>{
    showSnackbar(false);
  },1000)
});
setIsLoading(false)
}

const togglePasswordView = ()=>{
  setpasswordshow(!passwordshow);
}

return (
  <>
    <div className="container mx-auto px-4 h-full" >
    <MuiThemeProvider>
            <Snackbar style={{position:"absolute",top:30}} open={snackbar} message={message} autoHideDuration={6000}>
                </Snackbar>
              </MuiThemeProvider>
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-6/12 px-4">
        <LoadingOverlay
    active={isLoading}
spinner
text='Loading please wait...'
>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-blueGray-400 border-0" style={{backgroundColor:'white',width:100+"%"}}>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="text-blueGray-600 text-center mb-3 font-bold" >
              <div style={{fontSize:25,fontFamily:"Tenor Sans", borderBottomWidth:1,borderBottomStyle:"solid",borderBottomColor:"#eeeeee",padding:5}}>Welcome back!</div>
              </div>
              <form>
                <div className="relative w-full mb-3">
                  <label
                    className="block capitalise text-blueGray-600 text-l font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-l shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    value={email}
                    onChange={(email)=>{setEmail(email.target.value)}}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block capitalise text-blueGray-600 text-l font-bold mb-2"
                    htmlFor="grid-password"
                  
                  >
                    <div style={{flexDirection:"row",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    Password <FaEye size={18} style={{cursor:"pointer"}} onClick={()=>{togglePasswordView()}}></FaEye>
                    </div>
                    
                  </label>
                  <input
                    type={!passwordshow?"password":"text"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-l shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    value={password}
                    onChange={(password)=>{setPassword(password.target.value)}}
                    
                  />
                 
                  </div>

                <div className="text-center mt-6">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    style={{backgroundColor:'#285A84'}}
                    onClick={()=>{signin()}}
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-wrap mt-6 relative">
            <div className="w-1/2">
              <a
                href="#pablo"
                onClick={(e) => {e.preventDefault(); props.history.push('/auth/passwordreset')} }
                className="text-blue-200"
              >
                <small>Forgot password?</small>
              </a>
            </div>
            <div className="w-1/2 text-right">
              <Link to="/auth/register" className="text-blue-200">
                <small>Create new account</small>
              </Link>
            </div>
          </div>
          </LoadingOverlay>
        </div>
        
      </div>
    </div>
  </>
);
}
