import React, { useEffect, useState } from "react";

// components

import CardLineChart from "components/Cards/CardLineChart.js";
import HighestScore from "components/Cards/highestscore";
import McatScore from "components/Cards/mcatscore";
import GpaChart from "components/Cards/gpa"
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import Ethnicity from "components/Cards/ethnicity";
import { useSelector,useDispatch,connect } from 'react-redux';
import Race from "components/Cards/race";
import OwnerHeader from "components/Headers/ownerHeader";
import host from "config/host";
import DataAction from "actions/data";
import Tour from "reactour";

function Dashboard(props) {
  const[data,setData]=useState(null)
  const dispatch = useDispatch();
 props.data.then(value=>{
   if(value.data!=null)
   if(value.data.filesList.length>0)
   setData(value.data)
 })
 const [tourOpen,setTourOpen]=useState(true)
 const [tour,setTour]=useState(false);
 useEffect(() => {
   setTimeout(()=>{
   if(localStorage.getItem('token')){
  fetch(host.nameserver + '/file/?limit=10&skip=0', {
    method: 'GET',
    headers: {
        'Authorization': 'Bearer '+localStorage.getItem('token'),
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((res) => {
      if (res.status === 200) return res.json();
    })
    .then(async(data) => {
      console.log(data)
        dispatch(DataAction(data))
    })
    .catch((err) => {
        console.log(err)
    });
  }
},1000)
}, [])
const guidestate = useSelector(state => state.GuideReducer)
  guidestate.then(value=>{
    setTour(value.guidestate)
  })


const tourConfig = [
  {
    selector: '[data-tut="dashboard-graph"]',
    content: `This is an overview of modo predictions on your uploaded applicants.`
  },
  {
    selector: '[data-tut="graph1"]',
    content: `The graphs are to check that predictions are impartial.`
  },
  {
    selector: '[data-tut="manage-user"]',
    content: `Click here to explore Manage users`
  }
]
  return (
    <>
    <Tour
          onRequestClose={()=>{setTourOpen(false)}}
          steps={tourConfig}
          isOpen={tourOpen && tour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={"#5cb7b7"}
        />
      {data ? <div data-tut="dashboard-graph" className="flex flex-wrap" style={{marginTop:120}}>
        <div data-tut="graph1" className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
        <HighestScore />
        </div>
        <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
        <GpaChart/>
        </div>
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
        <Ethnicity />
        </div>
        <div className="w-full xl:w-12/12 px-4">
        <Race />
        </div>
        </div>:<h1 style={{display:"flex",justifyContent:"center",alignItems:"center",flex:1,color:"black",height:80+"vh"}}>There is no data to show here</h1>}
       
    
    </>
  );
}
const mapStateToProps = state => ({
  data: state.DataReducer,
});

export default connect(mapStateToProps)(Dashboard)