import DataReducer from 'reducers/data';
import GuideReducer from 'reducers/guide';
import JobDataReducer from 'reducers/jobdata';
import LoginReducer from 'reducers/Login';
import ResultsFilterReducer from 'reducers/resultsFilter';
import {combineReducers} from 'redux';

const RootReducer = combineReducers({
    LoginReducer:LoginReducer,
    DataReducer:DataReducer,
    JobDataReducer:JobDataReducer,
    ResultsFilterReducer:ResultsFilterReducer,
    GuideReducer:GuideReducer
})
export default RootReducer;