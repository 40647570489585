import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {connect, useSelector} from 'react-redux'
// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import AddCompany from "views/owner/addCompany";
import AdminNavbar from "components/Navbars/AdminNavbar";
import OwnerHeader from "components/Headers/ownerHeader";
import AllClients from "views/owner/allClients";

 function Owner(props) {
  const [loggedin, setLoggedin] = useState(true);
  const [role,setRole] = useState('owner');
  const loginstatus = useSelector(state => state.LoginReducer);
  loginstatus.then(val=>{
    console.log(val);
    if(val.Loggedin===false){
   if(!localStorage.getItem('loggedin')===true){
    props.history.push('/');
   }
    }
  })
  useEffect(() => {
    setLoggedin(localStorage.getItem('loggedin'));
    setRole(localStorage.getItem('role'));
  }, [])
    return (
        <div>
         <AdminNavbar props={props} />
          <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{backgroundColor:'#003366'}}
          ></div>
          <Switch>
           {loggedin && role==="owner" && <Route path="/owner/register" exact component={AddCompany} />}
            {loggedin && role==="owner" && <Route path="/owner/clients" exact component={AllClients} />}
            <Redirect from="/owner" to="/" />
          </Switch>
          <FooterSmall absolute />
        </section>
        </div>
    )
}
const mapStateToProps = state => ({
  loginstate: state.LoginReducer,
});

export default connect(mapStateToProps)(Owner)
